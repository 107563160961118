<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد قوانین لغو رزرو
        </b-button>
      </b-col>
    </b-row>
    <div v-if="rules">
      <div class="d-flex justify-content-between align-items-center">
         <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />
        <!-- <search
          ref="residencesSearchComponent"
          class="w-50"
          v-model="searchInput"
        /> -->
      </div>

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <!-- <b-th>عکس آیکن</b-th>
            <b-th>آیکن</b-th> -->
            <b-th>عنوان</b-th>
            <b-th>توضیحات</b-th>
            <b-th>تاریخ</b-th>
            <b-th>وضعیت</b-th>
            <b-th>عملیات</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="rule in rules" :key="rule.id">
            <!-- <b-th>
              <img
              v-if="rule.image.path"
                style="border-radius: 15px; width: 35px"
                :src="$http.defaults.fileUrl + rule.image.path"
            /></b-th>
            <b-th >{{ rule.icon }}</b-th> -->
            <b-td>{{ rule.name }}</b-td>
            <b-td>{{ rule.description }}</b-td>
            <b-td>
              {{
                new Date(rule.created_at).toLocaleString("fa-IR", {
                  timeZone: "Iran",
                })
              }}
            </b-td>
            <b-td>
              <b-col
                class="d-flex align-items-center pl-0"
                :class="
                  rule.status != 'active' ? 'text-danger' : 'text-success'
                "
              >
                {{ rule.status == "active" ? "" : "غیر" }}فعال
              </b-col></b-td
            >
            <b-td>
              <b-col class="d-flex align-items-center">
                <b-button
                  @click="showConfirmModal(rule)"
                  variant="flat-danger"
                  class="font-weight-bolder"
                >
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
                <b-button
                  @click="showFormModal(rule)"
                  variant="flat-secondary"
                  class="font-weight-bolder"
                >
                  <feather-icon icon="Edit2Icon" size="20" />
                </b-button> </b-col
            ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <pagination
        ref="residencesPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش قوانین لغو رزرو" : "ایجاد قوانین لغو رزرو" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-1">
          <label for="name" class="text-capitalize"> عنوان : </label>
          <b-form-input @input="removeErrors('name')" id="name" v-model="ruleInput.name" type="text" />
           <ErrorMessage
            errorType="name"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="slug" class="text-capitalize"> اسلاگ | slug : </label>
          <b-form-input @input="removeErrors('slug')" id="slug" v-model="ruleInput.slug" type="text" />
            <ErrorMessage
            errorType="slug"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
          <label for="province_status" class="text-capitalize"> وضعیت : </label>
          <b-form-checkbox
            v-model="ruleInput.status"
            :aria-describedby="ariaDescribedby"
            value="active"
            @input="removeErrors('status')"
            >فعال</b-form-checkbox
          >
          <b-form-checkbox
            v-model="ruleInput.status"
            :aria-describedby="ariaDescribedby"
            value="inactive"
            @input="removeErrors('status')"
            >پیش نویس</b-form-checkbox
          >
          <ErrorMessage
            errorType="status"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="description" class="text-capitalize"> توضیحات : </label>
          <b-form-input
            id="description"
            v-model="ruleInput.description"
            type="text"
            @input="removeErrors('description')"
          />
          <ErrorMessage
            errorType="description"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <!-- <b-form-group class="mt-1">
          <label for="body" class="text-capitalize"> آیکن : </label>
          <b-form-input @input="removeErrors('icon')"  id="body" v-model="ruleInput.body" type="text" />
              <ErrorMessage
            errorType="icon"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="">
          <label for="imageIcon" class="text-capitalize">
            آپلود تصویر ایکون:
          </label>
          <FilePicker
            @inputFile="inputIconImageFile"
            :url="iconImageurl"
            ref="iconImageFilePicker"
          ></FilePicker>
          <ErrorMessage
            errorType="image"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group> -->
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ErrorMessage from "@/components/ErrorMessage.vue";
import FilePicker from "@/components/FilePicker.vue";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    ErrorMessage,
    BFormCheckbox,
    FilePicker,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      iconImageurl:"",
      errorMessage: {},
      perPage: 10,
      page: 1,
      buildings: [],
      rules: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      buildingsCancelToken: undefined,
      ruleInput: {
        id: "",
        name: "",
        slug:"",
        description: "",
        status:"",
        image: null,
        icon: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getRules();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getRules();
    },
    perPage(value) {
      this.page = 1;
      this.getRules();
    },
    page(value) {
      this.getRules();
    },
  },
  methods: {
    inputIconImageFile(file) {
      this.removeErrors("image");
      this.ruleInput.image = file;
    },
    removeErrors(data) {
      if (
        typeof this.errorMessage[data] !== "undefined" &&
        this.errorMessage[data].length > 0
      ) {
        // this.errorMessage[data][0]="salllam"
        delete this.errorMessage[data];
        this.errorMessage = Object.assign(
          {},
          this.errorMessage,
          this.errorMessage
        );
        console.log(data, this.errorMessage);
      }
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getRules();
    },

    async getRules() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/residences/rules",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.rules = data.data;
          this.paginationData = data.meta;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(rule) {
      this.$bvModal
        .msgBoxConfirm(
          `آیا از حذف قوانین لغو رزرو مطمئن هستید ؟  : ${rule.name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let residenceIndex = 0;
            this.rules = await this.rules.filter(
              (residenceItem, index) => {
                if (residenceItem.id == rule.id) {
                  residenceIndex = index;
                }
                return residenceItem.id !== rule.id;
              }
            );

            const requestData = {
              method: "delete",
              url: `/admin/residences/rules/${rule.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(
                  `قوانین لغو رزرو: ${rule.name}  با موفقیت حذف شد.`,
                  {
                    title: this.$t("Done"),
                    variant: "success",
                    solid: true,
                  }
                );
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.rules.splice(residenceIndex, 0, rule);
              });
          }
        });
    },
    showFormModal(rule) {
      if (rule) {
        this.ruleInput = {
          id: rule.id,
          name: rule.name,
          slug:rule.slug,
          description: rule.description,
          status: rule.status,
          // image: null,
          // icon: rule.icon,
        };
        // this.$refs.iconImageFilePicker.clearFileInput()
        // this.iconImageurl=rule.image.path
        this.editMode = true;
      } else {
        this.ruleInput = {
          name: "",
          slug: "",
          description: "",
          status: "",
          // image: "",
          // icon: "",
        };
        // this.$refs.iconImageFilePicker.clearFileInput()
        // this.iconImageurl=""
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.ruleInput.name) {
        errorMessage = "لطفا نام قوانین لغو رزرو را وارد کنید";
      }
      if (!errorMessage && !this.ruleInput.slug) {
        errorMessage = "لطفا اسلاگ قوانین لغو رزرو را وارد کنید";
      }

      let formData = new FormData();

      if (this.ruleInput.name) {
        formData.append("name", this.ruleInput.name);
      }
      if (this.ruleInput.slug) {
        formData.append("slug", this.ruleInput.slug);
      }
      if (this.ruleInput.status) {
        formData.append("status", this.ruleInput.status);
      }
      if (this.ruleInput.description) {
        formData.append("description", this.ruleInput.description);
      }
      // if (this.ruleInput.image) {
      //   formData.append("image", this.ruleInput.image);
      // }
      // if (this.ruleInput.icon) {
      //   formData.append("icon", this.ruleInput.icon);
      // }
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "put" : "post",
        url: this.editMode
          ? `/admin/residences/rules/${this.ruleInput.id}`
          : `/admin/residences/rules`,
        data: this.ruleInput,
      };

      this.$http(requestData)
        .then((response) => {
          let message = "قوانین لغو رزرو با موفقیت اضافه شد";
          if (this.editMode) message = "قوانین لغو رزرو با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode && this.buildings && this.buildings.length > 0) {
            this.$refs.residencesPaginationComponent.clear();
            // this.$refs.residencesSearchComponent.clear();
            this.$refs.residencesTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getRules();
        })
        .catch((err) => {
          let message = "خطا در ایجاد قوانین لغو رزرو";
          if (this.editMode) message = "خطا در ویرایش قوانین لغو رزرو";
        if (
            err.response &&
            err.response.data &&
            err.response.data.response &&
            err.response.data.response.message
          )
            message = err.response.data.response.message;
          if (
            err.response &&
            err.response.data &&
            err.response.data.response &&
            err.response.data.response.meta &&
            err.response.data.response.meta.errors
          )
            this.errorMessage = err.response.data.response.meta.errors;
          console.log("this.errorMessage", this.errorMessage);

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
    },

  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
